import { Component, HostBinding, Input } from '@angular/core';

export type TagColor =
  | 'green'
  | 'red'
  | 'red-light'
  | 'light'
  | 'yellow'
  | 'grey'
  | 'grey-light'
  | 'orange'
  | 'orange-light'
  | 'blue'
  | 'magenta'
  | 'magenta-light'
  | 'black-light'
  | 'blue-light'
  | 'yellow-light'
  | 'dark-blue-light'
  | 'green-light';
export type TagSize = 'small' | 'normal';

@Component({
  selector: 'dm-ui-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() color: TagColor = 'grey';
  @Input() size: TagSize = 'small';

  @HostBinding('class') get hostClass(): string[] {
    return [this.size, this.color];
  }
}
