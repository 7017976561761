import { NativeAppStore } from '../../../../ui/src/lib/components/natie-app-badge-link/native-app-badge-link.component';

export interface IEnvironment {
  production: boolean;
  stage: boolean;
  staticUrl: string;
  apiUrl: string;
  crmUrl: string;
  sentry: string;
  tokenName: string;
  mapApiKey: string;
  mapId: string;
  cmsApiUrl: string;
  cmsKey: string;
  embeddedMap: string;
  googleTagManager: {
    booking: string;
    ncp: string;
  };
  googlePay: {
    scriptUrL: string;
  };
  openMobileAppUrl: string;
  recaptchaKey: string;
  appStores: { [key in NativeAppStore]: { url: string; id: string } };
  adyen: {
    environment: 'test' | 'live';
  };
  resetPasswordUrl: string;
  enableServiceWorker: boolean;
}
