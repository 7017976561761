import { Component, Inject, OnInit } from '@angular/core';
import { GoogleTagManagerService } from '@dm-workspace/core';
import { ENV } from '@dm-workspace/types';
import { IEnvironment } from '@dm-workspace/environments';

@Component({
  selector: 'dm-ob-app',
  templateUrl: './ob-app.component.html',
  styleUrls: ['./ob-app.component.scss'],
})
export class ObAppComponent implements OnInit {
  constructor(@Inject(ENV) private env: IEnvironment, private gtag: GoogleTagManagerService) {}

  ngOnInit(): void {
    this.gtag.inject(this.env.googleTagManager.booking);
  }
}
