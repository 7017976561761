import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ObAppComponent } from './ob-app.component';
import { BookingCoreModule } from '@dm-workspace/online-booking/core';
import { NgxMaskModule } from 'ngx-mask';
import { SentryCoreModule } from '@dm-workspace/sentry-core';
import { ServiceWorkerCoreModule } from '@dm-workspace/service-worker-core';
import { PAYMENT_METHOD_ADYEN_LIGHT_THEME_OPTIONS, PAYMENT_METHOD_ADYEN_OPTIONS } from '@dm-workspace/payment';
import { MarinasApiServiceToken } from '@dm-workspace/data-access';
import { ObMarinasApiService } from '@dm-workspace/data-access';

@NgModule({
  declarations: [ObAppComponent],
  providers: [
    {
      provide: PAYMENT_METHOD_ADYEN_OPTIONS,
      useValue: PAYMENT_METHOD_ADYEN_LIGHT_THEME_OPTIONS,
    },
    {
      provide: MarinasApiServiceToken,
      useClass: ObMarinasApiService,
    },
  ],
  imports: [BrowserModule, BookingCoreModule, SentryCoreModule, NgxMaskModule.forRoot(), ServiceWorkerCoreModule],
  bootstrap: [ObAppComponent],
})
export class ObAppModule {}
