import { LibsSharedModule } from '@dm-workspace/shared';
import { InputDateComponent } from './components/input-date/input-date.component';
import { TranslateModule } from '@ngx-translate/core';
import { RangeDatePickerComponent } from './components/range-date-picker/range-date-picker.component';
import { FormControlDirective, FormGroupComponent } from './components/form-group/form-group.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormErrorMessageComponent } from './components/form-error-messages/form-error-message.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { InputValueComponent } from './components/input-value/input-value.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { SelectAutocompleteComponent } from './components/select-autocomplete/select-autocomplete.component';
import { BoatDetailsFormComponent } from './components/boat-details-form/boat-details-form.component';
import { SelectTypeaheadComponent } from './components/select-typeahead/select-typeahead.component';
import { CalendarRangeComponent } from './components/calendar-range/calendar-range.component';
import { PasswordToggleComponent } from './components/password-toggle/password-toggle.component';
import { FormsFilesModule } from './modules/files/forms-files.module';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormInputIconComponent } from './components/form-input-icon/form-input-icon.component';
import { FormGroupCheckboxDirective } from './directives/form-group-checkbox.directive';
import { FormInputBlockEventPasteDirective } from './directives/form-input-block-event-paste.directive';
import { InputMinMaxDateDatepickerDirective } from './directives/input-min-max-date-datepicker.directive';
import { InputDatepickerDirective } from './directives/input-datepicker.directive';
import { ScrollToErrorDirective } from './directives/scroll-to-error.directive';
import { FormSelectOptionCountryComponent } from './components/form-select-option-contry/form-select-option-country.component';
import { FormSelectCountryDirective } from './directives/form-select-country.directive';
import { FormDateTimePickerComponent } from './components/form-date-time-picker/form-date-time-picker.component';
import { CheckboxComponent } from './components/checkbox/checkbox-old/checkbox.component';
import { FormRadioGroupComponent } from './components/radio/radio-group/form-radio-group.component';
import { RadioButtonVisualComponent } from './components/radio/radio-button-visual/radio-button-visual.component';
import { RadioCardComponent } from './components/radio/radio-card/radio-card.component';
import { RadioIconComponent } from './components/radio/radio-icon/radio-icon.component';
import { CheckboxCardComponent } from './components/checkbox/checkbox-card/checkbox-card.component';
import { FormCheckboxGroupComponent } from './components/checkbox/checkbox-group/form-checkbox-group.component';
import { CheckboxIconComponent } from './components/checkbox/checkbox-icon/checkbox-icon.component';
import { HighlightPipe } from './pipe/highlight/highlight.pipe';
import { SelectNotFoundComponent } from './components/select-not-found/select-not-found.component';
import { SearchCustomerComponent } from './components/search/search-customer/search-customer.component';
import { SearchBoatComponent } from './components/search/search-boat/search-boat.component';
import { SearchBerthComponent } from './components/search/search-berth/search-berth.component';
import { HasAccountBadgeComponent } from './components/search/search-customer/has-account-badge/has-account-badge.component';
import { CustomSearchNoResultDirective } from './components/search/custom-search-no-result.directive';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { FormSelectPhoneCountryDirective } from './directives/form-select-phone-country.directive';

const components = [
  AutocompleteComponent,
  FormErrorMessageComponent,
  FormGroupComponent,
  FormControlDirective,
  RangeDatePickerComponent,
  InputValueComponent,
  CheckboxCardComponent,
  FormCheckboxGroupComponent,
  InputDateComponent,
  CheckboxComponent,
  TextareaComponent,
  CheckboxIconComponent,
  FormRadioGroupComponent,
  SelectAutocompleteComponent,
  SelectTypeaheadComponent,
  BoatDetailsFormComponent,
  ScrollToErrorDirective,
  RadioButtonVisualComponent,
  RadioCardComponent,
  CalendarRangeComponent,
  RadioIconComponent,
  PasswordToggleComponent,
  SlideToggleComponent,
  FormDateTimePickerComponent,
  FormInputIconComponent,
  FormGroupCheckboxDirective,
  FormInputBlockEventPasteDirective,
  InputMinMaxDateDatepickerDirective,
  InputDatepickerDirective,
  SearchBerthComponent,
  HighlightPipe,
  SelectNotFoundComponent,
  FormSelectOptionCountryComponent,
  HasAccountBadgeComponent,
  FormSelectCountryDirective,
  FormSelectPhoneCountryDirective,
  SearchBoatComponent,
  CustomSearchNoResultDirective,
  SearchCustomerComponent,
  PhoneInputComponent,
];

const modules = [FormsModule, ReactiveFormsModule, NgSelectModule, FormsFilesModule, LibsSharedModule];

@NgModule({
  declarations: [...components],
  imports: [...modules, CommonModule, NgbModule, TranslateModule, NgbTypeaheadModule, NgxMaskModule.forChild()],
  exports: [...components, ...modules, NgxMaskModule],
})
export class LibsFormsModule {}
