import { isValid } from 'date-fns';
import { DateRange } from '@dm-workspace/types';
import { map, shareReplay, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export class DateUtils {
  public static ONE_DAY_TIME = 1000 * 3600 * 24;

  public static changeTimezone(date: string | Date | number, timeZone: string): Date {
    timeZone ??= 'Etc/GMT0';
    const dateTimezoneString = new Date(date).toLocaleString('en', { timeZone });
    return new Date(dateTimezoneString);
  }

  public static convertToDate(_date: string | number | Date): Date {
    if (_date instanceof Date) return _date;

    const date = new Date(_date);
    if (!isValid(date)) console.error('Invalid date');
    if (typeof _date === 'string' && _date.includes('T')) return date;
    date.setUTCHours(0, 0, 0, 0);
    return date;
  }

  public static getDateRangeDuration(dateRange: DateRange): number {
    if (!dateRange?.toDate || !dateRange?.fromDate) {
      return 0;
    }
    const fromDate = new Date(dateRange.fromDate);
    fromDate.setHours(0, 0, 0, 0);
    const toDate = new Date(dateRange.toDate);
    const dateRangeDiffTime = toDate.getTime() - fromDate.getTime();
    return Math.ceil(dateRangeDiffTime / DateUtils.ONE_DAY_TIME);
  }

  public static get currentTime$() {
    return timer(0, 1000).pipe(
      map(() => new Date()),
      distinctUntilChanged((p, n) => {
        return p.getMinutes() === n.getMinutes();
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
export function checkDateRangeIsValidDate(date: DateRange): boolean {
  if (date && date.toDate && date.fromDate) {
    return isValid(new Date(date.toDate)) && isValid(new Date(date.fromDate));
  }
  return false;
}
