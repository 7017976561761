import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ObAppModule } from './ob-app/ob-app.module';
import { ApiPrefix } from '@dm-workspace/types';
import { environment } from '@dm-workspace/environments';
import { DmSentry } from '@dm-workspace/sentry-core';

DmSentry.init({
  apiPrefix: ApiPrefix.onlineBooking,
  env: environment,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(ObAppModule)
  .catch((err) => console.error(err));
