import { Component, inject } from '@angular/core';
import { Marina } from '@dm-workspace/types';
import { Router } from '@angular/router';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-ob-create-booking-select-marina-view',
  templateUrl: './ob-create-booking-select-marina-view.component.html',
  styleUrls: ['./ob-create-booking-select-marina-view.component.scss'],
})
export class ObCreateBookingSelectMarinaViewComponent {
  #marinasService = inject(MarinasService);
  #router = inject(Router);
  protected readonly marinas$ = this.#marinasService.marinas$;

  protected onSelectMarina(marina: Marina) {
    this.#router.navigateByUrl(marina.code);
  }
}
