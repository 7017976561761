import { Directive, HostBinding, inject, Input, signal } from '@angular/core';
import { CHECKBOX_GROUP, FormCheckboxGroupComponent } from './form-checkbox-group.component';
import { ControlValueAccessor } from '@angular/forms';

let idCounter = 0;
@Directive()
export class CheckboxButtonClass implements ControlValueAccessor {
  writeValue(value: boolean): void {
    this.checked.set(value);
  }
  registerOnChange(fn: (_: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => undefined): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  @HostBinding('class.checkbox-button')
  @Input()
  id: string = 'CheckboxButtonClass' + ++idCounter;
  @Input() name: string;
  protected onChange: (selected: boolean) => void;
  protected onTouched: () => void = () => undefined;
  protected checkboxGroup?: FormCheckboxGroupComponent = inject(CHECKBOX_GROUP, { optional: true });
  public checked = signal<boolean>(false);
  public isDisabled = signal<boolean>(false);
  onModelChange() {
    if (this.isDisabled()) {
      return;
    }
    if (this.checkboxGroup) {
      this.checkboxGroup.setValue(this.name);
    } else {
      this.checked.update((state) => !state);
      this.onChange(!this.checked());
    }
  }
}
