import { ChangeDetectorRef, Component, forwardRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { LayoutService } from '@dm-workspace/core';
import { DateRange } from '@dm-workspace/types';
import { DATE_FORMAT_PLACEHOLDER } from '@dm-workspace/shared';

@Component({
  selector: 'dm-form-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeDatePickerComponent),
      multi: true,
    },
  ],
})
export class RangeDatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  #cd = inject(ChangeDetectorRef);
  @Input() public minDate: NgbDate;
  @Input() public maxDate: NgbDate;
  @Input() public rangeMaxDays: number;
  @Input() public placeholderStart: string;
  @Input() public placeholderEnd: string;
  @ViewChild(NgbInputDatepicker) public inputDatepicker: NgbInputDatepicker;

  protected destroy$ = new Subject<boolean>();
  protected maxDateRange: NgbDateStruct | null;
  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | NgbDateStruct;
  public toDate: NgbDate | NgbDateStruct;
  public displayMonths = 2;
  public datePlaceholderFormat = DATE_FORMAT_PLACEHOLDER;
  public isDisabled = false;
  private onChange: (params: DateRange) => void;
  private onTouch: () => void;

  constructor(
    public formatter: NgbDateParserFormatter,
    public calendar: NgbCalendar,
    public adapter: NgbDateAdapter<string>,
    private layout: LayoutService
  ) {}

  public ngOnInit(): void {
    this.layout.isMobile$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
      this.displayMonths = isMobile ? 1 : 2;
    });
  }

  public registerOnChange(fn: (params: DateRange) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public writeValue(obj: DateRange): void {
    const { fromDate, toDate } = obj || {};
    if (fromDate && toDate) {
      this.fromDate = this.adapter.fromModel(fromDate);
      this.toDate = this.adapter.fromModel(toDate);
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
    this.setMaxDate();
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.#cd.markForCheck();
  }
  public onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.emitDate();
      this.inputDatepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.resetValue();
    }
    this.setMaxDate();
  }

  public isHovered(date: NgbDate): boolean {
    return !!(
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate): boolean {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public stopEvents(input: HTMLInputElement): void {
    input.blur();
  }

  public emitDate(): void {
    if (!this.onChange) {
      return;
    }

    const fromDate = this.adapter.toModel(this.fromDate);
    const toDate = this.adapter.toModel(this.toDate);

    this.onChange({ fromDate, toDate });
    this.onTouch();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private resetValue(): void {
    if (!this.onChange) {
      return;
    }
    this.onChange(null);
    this.onTouch();
    this.setMaxDate();
  }

  toggleDatePicker(datepicker: NgbInputDatepicker) {
    if (this.isDisabled) {
      return;
    }
    datepicker.toggle();
  }

  setMaxDate() {
    if (this.maxDate) {
      this.maxDateRange = this.maxDate;
      return;
    }
    if (!this.fromDate || !this.rangeMaxDays || this.toDate) {
      this.maxDateRange = null;
      return;
    }
    const result = new Date(this.adapter.toModel(this.fromDate));
    result.setDate(result.getDate() + this.rangeMaxDays);
    this.maxDateRange = this.adapter.fromModel(result.toISOString());
    this.#cd.markForCheck();
  }
}
