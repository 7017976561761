import { Component, inject, Input } from '@angular/core';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-ob-booking-core-booking-payment-error-view',
  templateUrl: './ob-booking-payment-error-view.component.html',
  styleUrls: ['./ob-booking-payment-error-view.component.scss'],
})
export class ObBookingPaymentErrorViewComponent {
  #marinasService = inject(MarinasService);

  @Input() paymentId: string;
  @Input() errorCode: string;

  protected marina$ = this.#marinasService.selectedMarina$;
}
