<div class="wrap wrap--default">
  <h2 class="h1 text-center">
    {{ "SELECTED_MARINA" | translate }}
  </h2>

  <div class="row">
    <dm-form-group class="col-md-4 offset-md-4 mb-0">
      <ng-select
        dmForm
        [placeholder]="'SELECT_MARINA' | translate"
        [clearable]="false"
        (change)="onSelectMarina($event)"
      >
        <ng-option
          *ngFor="let marina of marinas$ | async | sortMarinasByCountry; trackBy: ('id' | trackByProperty)"
          [value]="marina"
        >
          <img [alt]="marina.name" [style.width.px]="20" class="me-1 vertical-align-middle" [src]="marina.countryCode | flag" />
          {{ marina.name }}
        </ng-option>
      </ng-select>
    </dm-form-group>
  </div>

  <dm-ui-horizontal-line />
</div>
