import { inject, NgModule } from '@angular/core';
import { Route, RouterModule, Routes, UrlSegment } from '@angular/router';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ObDownloadAppViewComponent } from './views/ob-download-app-view/ob-download-app-view.component';
import { ObQuoteRouteSnapshot, ObQuoteViewService } from '@dm-workspace/online-booking/quotes';
import { ObMainViewComponent } from './components/ob-main-view/ob-main-view.component';
import { PylonUtils } from '@dm-workspace/utils';
import { ObBookingPaymentErrorViewComponent } from './views/ob-booking-payment-error-view/ob-booking-payment-error-view.component';
import { MarinasService } from '@dm-workspace/core';
import { map } from 'rxjs';
import { ObCreateBookingSelectMarinaViewComponent } from './views/ob-create-booking-select-marina-view/ob-create-booking-select-marina-view.component';

export type ObPylonParams = {
  pylonId: string;
};

const routes: Routes = [
  {
    path: 'quotes/:quoteId',
    loadChildren: () => import('@dm-workspace/online-booking/quotes').then((m) => m.ObQuotesModule),
    canActivate: [
      (aRoute: ObQuoteRouteSnapshot) => {
        const quoteViewService = inject(ObQuoteViewService);
        quoteViewService.setQuoteRouteSnapshot(aRoute);
      },
    ],
    canDeactivate: [
      () => {
        inject(ObQuoteViewService).onDeactivateRoute();
      },
    ],
  },
  {
    path: '',
    component: ObMainViewComponent,
    children: [
      {
        path: 'download-app',
        component: ObDownloadAppViewComponent,
        data: {
          title: 'DOWNLOAD_APP_PAGE_HEADER',
        },
      },
      {
        path: 'select-marina',
        component: ObCreateBookingSelectMarinaViewComponent,
        data: {
          title: 'BOOKING_FROM_HEADER_STEP_1',
        },
      },
      {
        path: ':pylonId',
        canMatch: [
          (route: Route, segments: UrlSegment[]) => {
            const [pylonIdSegment] = segments;
            return PylonUtils.validateId(pylonIdSegment.path);
          },
        ],
        loadChildren: () => import('@dm-workspace/online-booking/pylon').then((m) => m.ObPylonModule),
      },
      {
        path: ':marinaCode',
        canActivate: [
          (route: ActivatedRouteSnapshot) => {
            const router = inject(Router);
            const marinaService = inject(MarinasService);
            const { marinaCode } = route.params;

            return marinaService.getMarinaByCode(marinaCode).pipe(
              map((marina) => {
                if (!marina) {
                  return router.parseUrl('/select-marina');
                }
                marinaService.selectMarina(marina);
                return true;
              })
            );
          },
        ],
        children: [
          {
            path: 'orders',
            loadChildren: () => import('@dm-workspace/online-booking/orders').then((m) => m.ObOrdersModule),
          },
          {
            path: 'payment-error',
            component: ObBookingPaymentErrorViewComponent,
            data: {
              title: 'BOOKING_PAYMENT_ERROR_HEADER',
            },
          },
          {
            path: '',
            loadChildren: () => import('@dm-workspace/online-booking/steps').then((m) => m.ObCreateBookingModule),
          },
        ],
      },
      {
        path: '**',
        redirectTo: 'select-marina',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top',
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class BookingCoreRoutingModule {}
