<input
  name="datepicker"
  class="hidden"
  ngbDatepicker
  #datepicker="ngbDatepicker"
  [autoClose]="'outside'"
  (dateSelect)="onDateSelection($event)"
  [displayMonths]="displayMonths"
  [dayTemplate]="t"
  outsideDays="hidden"
  [startDate]="fromDate!"
  [minDate]="minDate"
  [maxDate]="maxDateRange"
  [disabled]="isDisabled"
  tabindex="-1"
  navigation="select"
/>
<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
<div class="form-group" (click)="toggleDatePicker(datepicker)">
  <input
    #dpFromDate
    class="form-control"
    [placeholder]="placeholderStart || datePlaceholderFormat"
    name="dpFromDate"
    [disabled]="isDisabled"
    [value]="formatter.format(fromDate)"
    (focus)="stopEvents(dpFromDate)"
  />
  <span class="i-calendar"></span>
</div>
<span class="divider"></span>
<div class="form-group" (click)="toggleDatePicker(datepicker)">
  <input
    #dpToDate
    class="form-control"
    [placeholder]="placeholderEnd || datePlaceholderFormat"
    name="dpToDate"
    [disabled]="isDisabled"
    [value]="formatter.format(toDate)"
    (focus)="stopEvents(dpToDate)"
  />
  <span class="i-calendar"></span>
</div>
