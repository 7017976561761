<div *ngIf="marina$ | async as marina" class="wrap wrap--default">
  <h2
    class="h3 text-center"
    [innerHtml]="'BOOKING_PAYMENT_ERROR_CONTENT' | translate : { email: marina.additionalProperties?.contactInfo.email }"
  ></h2>

  <hr />

  <div class="row mb-4">
    <div class="col text-right label">{{ "MARINA" | translate }}:</div>
    <div class="col text-left">{{ marina.name }}</div>
  </div>
  <div class="row mb-4">
    <div class="col text-right label">{{ "PAYMENT_ID" | translate }}:</div>
    <div class="col text-left">{{ paymentId }}</div>
  </div>
  <div class="row mb-4">
    <div class="col text-right label">{{ "ERROR_CODE" | translate }}:</div>
    <div class="col text-left">{{ errorCode }}</div>
  </div>
</div>
