import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbNavModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LibsFormsModule } from '@dm-workspace/forms';
import { LibsSharedModule } from '@dm-workspace/shared';
import { ModulesModule } from './modules/modules.module';
import { LogoComponent } from './components/logo/logo.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { AnimatedFixedHeaderComponent } from './components/animated-fixed-header/animated-fixed-header.component';
import { CarrotComponent } from './components/carrot/carrot.component';
import { FullPageComponent } from './components/full-page/full-page.component';
import { InfoTextComponent } from './components/info-text/info-text.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { StatusDotComponent } from './components/status-dot/status-dot.component';
import { TabsNavigationComponent } from './components/tabs-navigation/tabs-navigation.component';
import { TestButtonsComponent } from './components/test-buttons/test-buttons.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { HiddenContentComponent } from './components/hidden-content/hidden-content.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ProgressDateComponent } from './components/progress-date/progress-date.component';
import { CountdownDateComponent } from './components/countdown-date/countdown-date.component';
import { MetaInformationComponent } from './components/meta-information/meta-information.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabItemDirective } from './components/tabs/tab-item.directive';
import { TagComponent } from './components/tag/tag.component';
import { StickyElementDirective } from './components/sticky-element/sticky-element.directive';
import { StickyElementTargetDirective } from './components/sticky-element/sticky-element-offset.directive';
import { BoatIconComponent } from './components/boat-icon/boat-icon.component';
import { BoatMarinaStatusComponent } from './components/boat-marina-status/boat-marina-status.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { AvailabilityInfoComponent } from './components/availability-info/availability-info.component';
import { IconTooltipComponent } from './components/icon-tooltip/icon-tooltip.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { PaymentListComponent } from './components/payment-list/payment-list.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { AvailabilityCheckComponent } from './components/availability-check/availability-check.component';
import { PaymentStatusBadgeComponent } from './components/payment-status-badge/payment-status-badge.component';
import { LoadMoreButtonComponent } from './components/load-more-button/load-more-button.component';
import { BoatCellComponent } from './components/boat-cell/boat-cell.component';
import { ModalComponent } from './modals/modal/modal.component';
import { TabNavComponent } from './components/tab-nav/tab-nav.component';
import { CommentsListComponent } from './components/comments-list/comments-list.component';
import { TicketsListComponent } from './components/tickets-list/tickets-list.component';
import { CopyInputComponent } from './components/copy-input/copy-input.component';
import { BadgeIconComponent } from './components/badge-icon/badge-icon.component';
import { NoItemsComponent } from './components/no-items/no-items.component';
import { PylonStateConfirmModalComponent } from './modals/confirm-modal/pylon-state-confirm-modal/pylon-state-confirm-modal.component';
import { NativeAppBadgeLinkComponent } from './components/natie-app-badge-link/native-app-badge-link.component';
import { HandOverConfirmModalComponent } from './modals/confirm-modal/handover-confirm-modal/hand-over-confirm-modal.component';
import { HorizontalLineComponent } from './components/horizontal-line/horizontal-line.component';
import { StateSwitchComponent } from './components/state-switch/state-switch.component';
import { StateSwitchItemLinkComponent } from './components/state-switch/state-switch-item/state-switch-item-link.component';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { BookingProgressComponent } from './components/booking-progress/booking-progress.component';
import { UiInfoComponent } from './components/ui-info/ui-info.component';
import { BoatAlertNotificationTemplateComponent } from './components/boat-alert-notification-template/boat-alert-notification-template.component';
import { StateSwitchSeparatorComponent } from './components/state-switch/state-switch-separator/state-switch-separator.component';
import { StateSwitchBadgeComponent } from './components/state-switch/state-switch-badge.component';
import { AlertComponent } from './components/alert/alert.component';
import { StateSwitchItemButtonComponent } from './components/state-switch/state-switch-item/state-switch-button.component';
import { BoatFlagComponent } from './components/boat-flag/boat-flag.component';
import { TagColorByBerthStatPipe } from './components/tag/tag-color-by-berth-stat.pipe';
import { RelativeDateFormatterComponent } from './components/relative-date-formatter/relative-date-formatter.component';
import { BoatImageComponent } from './components/boat-image/boat-image.component';
import { BoatDimensionsComponent } from './components/boat-dimensions/boat-dimensions.component';
import { DoubleArrowLineComponent } from './components/double-arrow-line/double-arrow-line.component';
import { VerticalLineComponent } from './components/vertical-line/vertical-line.component';
import { ConfirmationIconComponent } from './components/confirmatiion-icon/confirmation-icon.component';

const components = [
  LogoComponent,
  AnimatedFixedHeaderComponent,
  UserInfoComponent,
  StatusDotComponent,
  HiddenContentComponent,
  VerticalLineComponent,
  ToggleSwitchComponent,
  PaymentStatusBadgeComponent,
  CarrotComponent,
  PaginationComponent,
  FullPageComponent,
  InfoTextComponent,
  ModalComponent,
  TestButtonsComponent,
  AlertComponent,
  TabsNavigationComponent,
  BoatImageComponent,
  StepperComponent,
  BoatDimensionsComponent,
  ProgressDateComponent,
  CountdownDateComponent,
  MetaInformationComponent,
  TabsComponent,
  TabItemDirective,
  TagComponent,
  StickyElementDirective,
  DoubleArrowLineComponent,
  TagColorByBerthStatPipe,
  StickyElementTargetDirective,
  BoatIconComponent,
  BoatCellComponent,
  BookingProgressComponent,
  BoatMarinaStatusComponent,
  ConfirmModalComponent,
  StateSwitchItemButtonComponent,
  PylonStateConfirmModalComponent,
  HandOverConfirmModalComponent,
  AvailabilityInfoComponent,
  AvailabilityCheckComponent,
  IconTooltipComponent,
  InfoBoxComponent,
  PaymentListComponent,
  DrawerComponent,
  LoadMoreButtonComponent,
  CopyInputComponent,
  BadgeIconComponent,
  NoItemsComponent,
  NativeAppBadgeLinkComponent,
  HorizontalLineComponent,
  StateSwitchComponent,
  StateSwitchItemLinkComponent,
  PrintButtonComponent,
  TabNavComponent,
  CommentsListComponent,
  TicketsListComponent,
  UiInfoComponent,
  BoatAlertNotificationTemplateComponent,
  StateSwitchBadgeComponent,
  BoatFlagComponent,
  StateSwitchSeparatorComponent,
  RelativeDateFormatterComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components, ModulesModule, NgbDropdownModule, NgbTooltipModule, ConfirmationIconComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ConfirmationIconComponent,
    ModulesModule,
    RouterModule,
    NgbPaginationModule,
    NgbNavModule,
    LibsSharedModule,
    LibsFormsModule,
    NgbTooltipModule,
  ],
})
export class UiModule {}
