import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { API_PREFIX, ApiPrefix, APP_TOKEN, AppToken, ENV, SELECTED_MARINA } from '@dm-workspace/types';
import { environment } from '@dm-workspace/environments';
import { BehaviorSubject } from 'rxjs';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  MarinaCodeInterceptor,
  TranslateCoreModule,
} from '@dm-workspace/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationModule } from '@dm-workspace/notification';
import { UiModule } from '@dm-workspace/ui';
import { LibsFormsModule } from '@dm-workspace/forms';
import { BookingCoreRoutingModule } from './booking-core-routing.module';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { isAnimationsDisabled } from '@dm-workspace/utils';
import { ObDownloadAppViewComponent } from './views/ob-download-app-view/ob-download-app-view.component';
import { ObSharedModule } from '@dm-workspace/online-booking/ob-shared';
import { ObCreateBookingSelectMarinaViewComponent } from './views/ob-create-booking-select-marina-view/ob-create-booking-select-marina-view.component';
import { ObBookingPaymentErrorViewComponent } from './views/ob-booking-payment-error-view/ob-booking-payment-error-view.component';
import { ObMainViewComponent } from './components/ob-main-view/ob-main-view.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule.withConfig({ disableAnimations: isAnimationsDisabled }),
    HttpClientModule,
    HttpClientJsonpModule,
    UiModule,
    LibsFormsModule,
    NotificationModule,
    TranslateCoreModule,
    BookingCoreRoutingModule,
    ObSharedModule,
  ],
  providers: [
    {
      provide: SELECTED_MARINA,
      useValue: new BehaviorSubject(null),
    },
    {
      provide: APP_TOKEN,
      useValue: AppToken.onlineBooking,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: MarinaCodeInterceptor,
    },
    {
      provide: API_PREFIX,
      useValue: ApiPrefix.onlineBooking,
    },
    {
      provide: ENV,
      useValue: environment,
    },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
  ],
  exports: [RouterModule, HttpClientModule],
  declarations: [
    ObMainViewComponent,
    ObCreateBookingSelectMarinaViewComponent,
    ObBookingPaymentErrorViewComponent,
    ObDownloadAppViewComponent,
    ObBookingPaymentErrorViewComponent,
  ],
})
export class BookingCoreModule {}
